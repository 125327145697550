import React from 'react'
import './style.scss'
import { Link } from 'gatsby'

const CP = (props) => {
  return (
    <div className={`cpLine ${props.className}`}>
      <div className='caterpillar'>{props.text}</div>
      <div className='caterpillar'>{props.text}</div>
      <div className='caterpillar'>{props.text}</div>
      <div className='caterpillar'>{props.text}</div>
      <div className='caterpillar'>{props.text}</div>
      <div className='caterpillar'>{props.text}</div>
      <div className='caterpillar'>{props.text}</div>
    </div>
  )
}

const Caterpillars = () => {
  return (
    <div className='caterpillarsContainer'>
      <Link to={'/illustration'}>
        <CP className='cp1' text={'Illustration'} />
      </Link>
      <Link to={'/gugu-zine'}>
        <CP className='cp3' text={'GuGu-zine'} />
      </Link>
      <Link to={'/contact-me'}>
        <CP className='cp4' text={'Contact me'} />
      </Link>
    </div>
  )
}

export default Caterpillars
