import React from 'react'
import './style.scss'

import B from './b.png'
import F from './f.png'

const StealTheStars = (props) => {
  let opacityFront = { opacity: props.percentage }
  return (
    <div className='stealTheStarsContainer'>
      <img src={F} className='f' style={opacityFront} alt='前景' />
      <img src={B} className='b' alt='背景' />
    </div>
  )
}

export default StealTheStars
