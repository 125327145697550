import React from 'react'

import guanText from './images/guan.png'
import starA from './images/starA.png'
import starB from './images/starB.png'
import faceStarPNG from './images/face-star.png'
import head from './images/头.png'
import leftHand from './images/leftHand.png'
import rightHand from './images/rightHand.png'
import body from './images/身体.png'
import './style.scss'

class Star {
  constructor(w, h, x, y, src) {
    this.w = w
    this.h = h
    this.x = x
    this.y = y - 35
    if (src) {
      this.src = src
    } else {
      this.src = Math.random() > 0.5 ? starA : starB
    }
  }

  up(top) {
    let x = this.x
    let y = this.y * (1 - top) * 1.2
    return [x, y]
  }

  down(top) {
    let x = this.x - top * 100
    let y = this.y * (1 + top)
    return [x, y]
  }
}

const genStars = () => {
  let smallSize = 20
  let midSize = 40
  let bigSize = 60

  let stars = [
    new Star(smallSize, smallSize, 0, 200),
    new Star(smallSize, smallSize, 50, 250),
    new Star(smallSize, smallSize, 130, 220),
    new Star(smallSize, smallSize, 210, 270),
    new Star(smallSize, smallSize, 230, 190),
    new Star(smallSize, smallSize, 270, 300),
    new Star(smallSize, smallSize, 350, 200),

    new Star(midSize, midSize, 30, 300),
    new Star(midSize, midSize, 30, 400),
    new Star(midSize, midSize, 90, 280),
    new Star(midSize, midSize, 120, 330),
    new Star(midSize, midSize, 200, 360),
    new Star(midSize, midSize, 280, 310),
    new Star(midSize, midSize, 330, 290),
    new Star(midSize, midSize, 320, 380),
    new Star(midSize, midSize, 340, 440),

    new Star(bigSize, bigSize, -20, 440),
    new Star(bigSize, bigSize, -10, 640),
    new Star(bigSize, bigSize, 70, 590),
    new Star(bigSize, bigSize, 80, 390),
    new Star(bigSize, bigSize, 100, 500),
    new Star(bigSize, bigSize, 200, 420),
    new Star(bigSize, bigSize, 260, 520),
    new Star(bigSize, bigSize, 340, 340),
    new Star(bigSize, bigSize, 320, 600),
  ]

  return stars
}

let stars = genStars()
let faceStar = new Star(60, 40, 320, 330, faceStarPNG)

const StarWithGirl = (props) => {
  let per = props.percentage < 0.8 ? props.percentage : 0.8 // 超过 0.8 说明快划走了
  let [fx, fy] = faceStar.down(per)
  let rightHandDeg = per * 25

  return (
    <div className='starWithGirlContainer'>
      <div className='textBlock'>
        <img src={guanText} alt='标题' />
      </div>
      <div className='relative bottom-36 se-content'>
        {stars.map((star, index) => {
          let [x, y] = star.up(per)
          return (
            <img
              src={star.src}
              key={index}
              className='star'
              alt='小星星'
              style={{
                left: x,
                top: y,
                width: star.w,
                height: star.h,
              }}
            />
          )
        })}
        <img
          src={faceStar.src}
          className='star faceStar'
          alt='特殊的有脸的星星'
          style={{
            left: fx,
            top: fy,
            width: faceStar.w,
            height: faceStar.h,
          }}
        />
        <img
          src={head}
          className='girl'
          style={{ height: 180, left: 100, top: 400, zIndex: 4 }}
          alt='女孩头'
        />

        <img
          src={leftHand}
          className='girl'
          style={{ height: 60, top: 542, left: 90 }}
          alt='女孩左手'
        />
        <img
          id='rightHand'
          src={rightHand}
          className='girl'
          style={{ height: 55, top: 545, left: 200, transform: `rotate(-${rightHandDeg}deg)` }}
          alt='女孩右手'
        />
        <img
          src={body}
          className='girl'
          style={{ width: 100, top: 545, left: 130 }}
          alt='女孩身体'
        />
        <div className='grassland' />
      </div>
    </div>
  )
}

export default StarWithGirl
