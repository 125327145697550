import React from 'react'
import './style.scss'

import star3URL from './yellowStar3.svg'
import star3NoFillURL from './yellowStar3NoFill.svg'
import textGroup from './textGroup.svg'

const YellowStar3 = (props) => {
  let scale = (props.percentage + 0.6) * 1.2
  let starScale = { transform: `scale(${scale})` }
  let move = (props.percentage + 0.6) * 120 // todo 这里没有考虑到 -120% 的情况
  let rightMove = { left: `${move}%` }
  return (
    <div className='yellowStar3Container'>
      <img src={textGroup} className='textGroup' style={rightMove} alt='文本组' />
      <img src={star3URL} className='yellowStar3' style={starScale} alt='三颗星星缩放' />
      <img
        src={star3NoFillURL}
        className='yellowStar3 noFill'
        style={starScale}
        alt='三颗星星轮廓'
      />
    </div>
  )
}

export default YellowStar3
