import React, { useEffect, useState } from 'react'
import { huabans, buttons } from './flower-svgs'
import './styles.scss'

const flowersArray = [
  { key: '1-1' },
  { key: '1-2' },
  { key: '1-3' },
  { key: '1-4' },
  { key: '2-1' },
  { key: '2-2' },
  { key: '2-3' },
  { key: '2-4' },
  { key: '3-1' },
  { key: '3-2' },
  { key: '3-3' },
  { key: '3-4' },
  { key: '3-5' },
  { key: '3-6' },
  { key: '3-7' },
  { key: '3-8' },
  { key: '4-1' },
  { key: '4-2' },
  { key: '4-3' },
  { key: '4-4' },
  { key: '4-5' },
  { key: '4-6' },
  { key: '4-7' },
  { key: '4-8' },
]

const Flower = () => {
  let [fillDict, setFillDict] = useState({})
  let [foucsBan, setFoucsBan] = useState(1)

  const fillBan = (ban) => {
    // 取本组
    let group = flowersArray.filter((i) => i.key.startsWith(foucsBan))
    let pushDict = {}
    group.forEach((i) => {
      pushDict[i.key] = { render: ban.svg, k: i.key, banIndex: ban.k }
    })
    setFillDict({
      ...fillDict, // 留着原来的
      ...pushDict, // 加上新的（2-1，2-2，2-3，2-4）这种结构
    })

    // 取下一组
    let nextBan = parseInt(foucsBan) + 1
    if (nextBan <= 5) {
      setFoucsBan(nextBan)
    }
  }

  const save = () => {
    let saveDict = {}
    Object.keys(fillDict).forEach((k) => {
      saveDict[k] = fillDict[k].banIndex
    })
    let str = JSON.stringify(saveDict)
    localStorage.setItem('flowerDict', str)
  }

  const back = () => {
    let num = foucsBan - 1
    if (num < 1) {
      return
    }
    let pushDict = {}
    let clearImage = {}
    Object.keys(fillDict).forEach((k) => {
      if (!k.startsWith(num)) {
        pushDict[k] = fillDict[k]
      } else {
        clearImage[k] = fillDict[k].banIndex
      }
    })
    setFillDict(pushDict)
    setFoucsBan(num)
  }

  useEffect(() => {
    let str = localStorage.getItem('flowerDict')
    if (!str) {
      return
    }
    let flowerDict = JSON.parse(str)
    let pushDict = {}
    Object.keys(flowerDict).forEach((k) => {
      let ban = huabans.find((i) => i.k === flowerDict[k])
      pushDict[k] = { render: ban.svg, k: k, banIndex: ban.k }
    })
    setFillDict(pushDict)
  }, [])

  return (
    <div className='flowerCanvas mx-auto w-screen sm:w-96'>
      <div className='flex'>
        <div className='menu flex-shrink-0'>
          {huabans.map((i) => {
            return (
              <button key={i.k} className='item' onClick={() => fillBan(i)}>
                {i.svg}
              </button>
            )
          })}
        </div>
        <div className='canvaContainer'>
          <div className='guide'>选择右边的图形生成自己的花花</div>
          <div className='relative h-full flex item-end justify-center'>
            <div className='gan absolute bottom-0'></div>
            <div className='huaduo'>
              <div className='huarui'></div>
              <div className='huaban'>
                {flowersArray.map((i) => {
                  return (
                    <div key={i.key} className={`ban ban-${i.key}`}>
                      {fillDict[i.key]?.render}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex h-20 p-1'>
        <button
          className=' flex items-center justify-center w-1/3 m-0.5'
          style={{ background: '#EEC71C' }}
          onClick={() => back()}
        >
          {buttons.back}
        </button>
        <button
          className=' flex items-center justify-center w-2/3 m-0.5'
          style={{ background: '#EEC71C' }}
          onClick={() => save()}
        >
          {buttons.save}
        </button>
      </div>
    </div>
  )
}

export default Flower
