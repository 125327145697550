import React from 'react'

export const fss = {
  ban1: (
    <svg
      width='50'
      height='50'
      viewBox='0 0 102 101'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M102 0H0V101H102V0Z' fill='#EB5379' />
    </svg>
  ),
  ban2: (
    <svg
      width='64'
      height='78'
      viewBox='0 0 128 156'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 79.1642C35.0249 81.7042 62.8325 114.936 64.1061 155.788C65.1675 114.725 92.9751 81.7042 128 79.1642C92.3383 76.6242 63.8939 42.1221 63.8939 0C63.8939 42.1221 35.6617 76.6242 0 79.1642Z'
        fill='#5C60A0'
      />
    </svg>
  ),
  ban3: (
    <svg
      width='51'
      height='51'
      viewBox='0 0 102 102'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_33_107)'>
        <path
          d='M51.0979 102.196C79.3185 102.196 102.196 79.3185 102.196 51.0979C102.196 22.8773 79.3185 0 51.0979 0C22.8773 0 0 22.8773 0 51.0979C0 79.3185 22.8773 102.196 51.0979 102.196Z'
          fill='#C996CE'
        />
      </g>
      <defs>
        <clipPath id='clip0_33_107'>
          <rect width='102' height='102' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
  ban4: (
    <svg
      width='59'
      height='18.5'
      viewBox='0 0 118 37'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M99.2015 37H18.7985C8.38004 37 0 28.753 0 18.5C0 8.24699 8.38004 0 18.7985 0H99.2015C109.62 0 118 8.24699 118 18.5C118 28.753 109.62 37 99.2015 37Z'
        fill='#EF8619'
      />
    </svg>
  ),
  ban5: (
    <svg
      width='71'
      height='25.5'
      viewBox='0 0 142 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.8327 46.5261C40.5336 46.5261 50.0192 37.161 50.0192 25.6087C50.0192 14.0564 40.5336 4.69141 28.8327 4.69141C17.1317 4.69141 7.64613 14.0564 7.64613 25.6087C7.64613 37.161 17.1317 46.5261 28.8327 46.5261Z'
        stroke='#539C58'
        strokeWidth='7'
        strokeMiterlimit='10'
      />
      <path
        d='M71 46.5261C82.701 46.5261 92.1865 37.161 92.1865 25.6087C92.1865 14.0564 82.701 4.69141 71 4.69141C59.299 4.69141 49.8135 14.0564 49.8135 25.6087C49.8135 37.161 59.299 46.5261 71 46.5261Z'
        stroke='#539C58'
        strokeWidth='7'
        strokeMiterlimit='10'
      />
      <path
        d='M113.167 46.5261C124.868 46.5261 134.354 37.161 134.354 25.6087C134.354 14.0564 124.868 4.69141 113.167 4.69141C101.466 4.69141 91.9808 14.0564 91.9808 25.6087C91.9808 37.161 101.466 46.5261 113.167 46.5261Z'
        stroke='#539C58'
        strokeWidth='7'
        strokeMiterlimit='10'
      />
    </svg>
  ),
  ban6: (
    <svg
      width='65'
      height='26.5'
      viewBox='0 0 130 53'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_33_113)'>
        <path
          d='M65 53.1956C100.899 53.1956 130 41.2873 130 26.5978C130 11.9082 100.899 0 65 0C29.1015 0 0 11.9082 0 26.5978C0 41.2873 29.1015 53.1956 65 53.1956Z'
          fill='#FF5160'
        />
      </g>
      <defs>
        <clipPath id='clip0_33_113'>
          <rect width='130' height='53' fill='white' />
        </clipPath>
      </defs>
    </svg>
  ),
  ban7: (
    <svg
      width='55'
      height='55'
      viewBox='0 0 109 108'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M54.5 104C82.3904 104 105 81.6142 105 54C105 26.3858 82.3904 4 54.5 4C26.6096 4 4 26.3858 4 54C4 81.6142 26.6096 104 54.5 104Z'
        stroke='#5C60A0'
        strokeWidth='7'
        strokeMiterlimit='10'
      />
    </svg>
  ),
  ban8: (
    <svg width='44' height='44' viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M84 4H4V84H84V4Z' stroke='#EF8619' strokeWidth='7' strokeMiterlimit='10' />
    </svg>
  ),
}

export const buttons = {
  back: (
    <svg
      width='60'
      height='50'
      viewBox='0 0 122 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.2381 37.082H55.4286C89.3333 37.082 116.762 64.6445 116.762 98.7149'
        stroke='white'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M38.6667 67.8984L7.42857 36.5078L38.6667 5.11719'
        stroke='white'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  ),
  undo: (
    <svg
      width='60'
      height='50'
      viewBox='0 0 122 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M110.762 37.082H66.7619C32.8572 37.082 5.42857 64.6445 5.42857 98.7149'
        stroke='white'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M83.3333 67.8984L114.571 36.5078L83.3333 5.11719'
        stroke='white'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  ),
  save: (
    <svg
      width='161'
      height='53.5'
      viewBox='0 0 322 107'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.8972 65.1133L53.1958 96.5039L84.4943 65.1133'
        stroke='white'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M53.1958 5.96875V93.4414'
        stroke='white'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M5.4845 101.863H99.9528'
        stroke='white'
        strokeWidth='10'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
      <path
        d='M144.038 78.8945L158.351 77.1719C158.924 79.4687 159.687 81 160.832 81.957C161.977 82.9141 163.313 83.2969 165.031 83.2969C166.939 83.2969 168.466 82.7226 169.42 81.7656C170.184 81 170.565 80.043 170.565 78.7031C170.565 77.3633 169.993 76.4062 169.039 75.6406C168.275 75.0664 166.176 74.4922 162.932 73.7266C157.97 72.5781 154.535 71.6211 152.626 70.6641C150.718 69.707 149 67.9844 147.664 65.6875C146.328 63.3906 145.756 60.7109 145.756 57.8398C145.756 54.5859 146.519 51.9062 147.855 49.6094C149.382 47.3125 151.29 45.5898 153.771 44.4414C156.252 43.293 159.687 42.7188 163.886 42.7188C168.275 42.7188 171.711 43.1016 173.81 44.0586C175.909 45.0156 177.627 46.3555 179.154 48.2695C180.489 50.1836 181.825 52.6719 182.78 55.9258L169.23 57.6484C168.848 56.1172 168.275 54.9687 167.512 54.2031C166.367 53.2461 165.031 52.6719 163.504 52.6719C161.977 52.6719 160.642 53.0547 159.878 53.8203C159.115 54.5859 158.733 55.543 158.733 56.5C158.733 57.6484 159.115 58.6055 160.069 59.1797C161.023 59.7539 163.123 60.3281 166.176 60.9023C170.947 61.668 174.573 62.625 176.863 63.7734C179.154 64.9219 181.062 66.8359 182.207 69.1328C183.352 71.4297 184.115 73.918 184.115 76.7891C184.115 79.6602 183.543 82.3398 182.207 85.0195C180.871 87.6992 178.772 89.8047 176.1 91.3359C173.237 92.8672 169.42 93.6328 164.649 93.6328C157.779 93.6328 153.008 92.293 149.954 89.8047C146.71 87.125 144.801 83.4883 144.038 78.8945Z'
        fill='white'
      />
      <path
        d='M203.773 59.5625L190.032 57.6484C190.604 54.5859 191.368 52.0977 192.322 50.1836C193.276 48.2695 194.612 46.9297 196.52 45.5898C197.856 44.6328 199.574 43.8672 201.864 43.293C204.154 42.7187 206.635 42.5273 209.307 42.5273C213.696 42.5273 217.132 42.9102 219.613 43.4844C222.285 44.0586 224.384 45.3984 226.101 47.5039C227.246 48.8438 228.201 50.7578 228.964 53.4375C229.727 55.9258 230.109 58.4141 230.109 60.7109V82.3398C230.109 84.6367 230.3 86.3594 230.491 87.6992C230.682 89.0391 231.254 90.5703 232.018 92.6758H218.468C217.895 91.3359 217.513 90.3789 217.323 89.8047C217.132 89.2305 216.941 88.082 216.75 86.7422C214.842 89.0391 212.933 90.7617 211.215 91.7187C208.735 93.0586 205.681 93.8242 202.437 93.8242C198.047 93.8242 194.612 92.4844 192.322 89.8047C190.032 87.125 188.887 83.8711 188.887 79.8516C188.887 76.2148 189.65 73.1523 191.368 70.6641C193.085 68.3672 196.139 66.4531 200.528 65.3047C205.872 63.9648 209.498 62.8164 211.025 62.4336C212.551 62.0508 214.269 61.0937 216.177 60.1367C216.177 57.8398 215.796 56.3086 215.032 55.3516C214.269 54.3945 212.933 54.0117 211.215 54.0117C208.925 54.0117 207.017 54.5859 205.872 55.543C205.108 55.9258 204.345 57.2656 203.773 59.5625ZM216.177 69.3242C214.269 70.2812 212.17 71.0469 210.07 71.8125C207.208 72.7695 205.299 73.7266 204.536 74.875C203.773 75.832 203.391 76.9805 203.391 78.3203C203.391 79.8516 203.773 81 204.536 81.957C205.299 82.9141 206.444 83.2969 207.971 83.2969C209.498 83.2969 211.025 82.7226 212.361 81.7656C213.696 80.8086 214.651 79.4688 215.223 78.1289C215.796 76.7891 216.177 74.875 216.177 72.3867V69.3242Z'
        fill='white'
      />
      <path
        d='M233.354 43.6758H248.239L255.492 74.4922L263.125 43.6758H277.63L261.789 92.4844H249.003L233.354 43.6758Z'
        fill='white'
      />
      <path
        d='M321.524 72.7695H292.706C292.897 75.832 293.661 77.9375 294.615 79.4688C295.951 81.5742 297.859 82.7227 299.958 82.7227C301.294 82.7227 302.63 82.3398 303.966 81.3828C304.73 80.8086 305.493 79.8516 306.447 78.3203L320.57 80.043C318.47 85.0195 315.799 88.4648 312.745 90.5703C309.691 92.6758 305.302 93.8242 299.577 93.8242C294.615 93.8242 290.798 92.8672 287.935 91.1445C285.072 89.2305 282.782 86.3594 280.874 82.5312C278.965 78.7031 278.011 73.918 278.011 68.5586C278.011 60.9023 279.92 54.7773 283.546 49.9922C287.363 45.207 292.515 42.9102 299.004 42.9102C304.348 42.9102 308.546 44.0586 311.791 46.1641C314.844 48.2695 317.325 51.332 318.852 55.3516C320.57 59.3711 321.333 64.5391 321.333 71.0469V72.7695H321.524ZM306.829 63.7734C306.638 60.1367 305.875 57.6484 304.539 55.9258C303.203 54.2031 301.676 53.6289 299.768 53.6289C297.477 53.6289 295.569 54.7773 294.233 57.0742C293.279 58.6055 292.897 60.7109 292.515 63.582H306.829V63.7734Z'
        fill='white'
      />
    </svg>
  ),
}

export const huabans = [
  { k: 1, svg: fss.ban1 },
  { k: 2, svg: fss.ban2 },
  { k: 3, svg: fss.ban3 },
  { k: 4, svg: fss.ban4 },
  { k: 5, svg: fss.ban5 },
  { k: 6, svg: fss.ban6 },
  { k: 7, svg: fss.ban7 },
  { k: 8, svg: fss.ban8 },
]
