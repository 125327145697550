import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { ParallaxFC } from 'fixed-scroll'

import StarWithGirl from '../components/starWithGirl'
import Caterpillars from '../components/caterpillars'
import YellowStar3 from '../components/yellowStar3'
import StealTheStars from '../components/stealTheStars'
import Flower from '../components/flower'

import '../styles/index.css'

const Index = () => {
  const [top, setTop] = useState(0)
  const [topDistance, setTopDistance] = useState(0)
  const cr = useRef()

  // useEffect(() => {
  //   const onScroll = () => {
  //     // console.log('外部监听方案', cr.current)
  //     if (cr.current) {
  //       setTop(cr.current.percentageTop)
  //       setTopDistance(cr.current.topDistance)
  //     }
  //   }
  //   window.addEventListener('scroll', onScroll)
  //   return () => window.removeEventListener('scroll', onScroll)
  // }, [])

  return (
    <main>
      <Helmet>
        <title>Guanyenc Land</title>
      </Helmet>
      <ParallaxFC.Container
        render={(distance) => {
          return (
            <>
              <ParallaxFC.StickySection
                distance={distance}
                top={0}
                scrollRange='300vh'
                render={(percentage) => {
                  return (
                    <>
                      <StarWithGirl percentage={percentage} />
                    </>
                  )
                }}
              />

              <Caterpillars />

              <ParallaxFC.StickySection
                distance={distance}
                top={50}
                scrollRange='200vh'
                render={(percentage) => {
                  return (
                    <div>
                      <YellowStar3 percentage={percentage} />
                      <StealTheStars percentage={percentage} />
                    </div>
                  )
                }}
              />
              <Flower />
            </>
          )
        }}
      />
    </main>
  )
}

export default Index
